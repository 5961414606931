import { useIonRouter } from '@ionic/react';
import { Box, ButtonFixedFooterLayout, ButtonLayout, ButtonPrimary, Checkbox, DateField, EmailField, Form, GridLayout, IconInformationUserRegular, Inline, IntegerField, ResponsiveLayout, Select, skinVars, Stack, Switch, Tabs, Text1, Text2, Text3, Text5, TextField, TextLink, Tooltip, useScreenSize } from '@telefonica/mistica';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from '../../../../../components/alerts/Alert';
import { useDigitalSign } from '../../../../../components/digital-sign/DigitalSign';
import LayoutPrivateArea from '../../../../../components/layout/LayoutPrivateArea';
import { useSignOperationsKey } from '../../../../../components/operations-key/SignOperationsKey';
import { ListOption } from '../../../../../entities/commons/list-option.model';
import { getMasterDatas, setDataClient, trackStadistics } from '../../../../../utils/apiclient';
import { getCurrentLang } from '../../../../../utils/locate';
import { addUser, getOperationKey, getTokenSign, getUser } from '../../../../../utils/storage';
import { useToast } from '../../../../../utils/toast';
import { getPlatform, validateUTCCodificationDate } from '../../../../../utils/utils';

const ProfileCustomerPage: React.FC = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const screenSize = useScreenSize();

  const { sign } = useSignOperationsKey()
  const { showToast } = useToast()
  const { digitalSign } = useDigitalSign();
  const { showAlert } = useAlert()

  const [loading, setLoading] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [visibleOtherActivitySector, setVisibleOtherActivitySector] = useState(false);


  const [genderOptions, setGenderOptions] = React.useState([] as ListOption[])
  const [countryOptions, setCountryOptions] = React.useState([] as ListOption[])
  const [provinceOptions, setProvinceOptions] = React.useState([] as ListOption[])
  const [viaOptions, setViaOptions] = React.useState([] as ListOption[])
  const [employmentSituationOptions, setEmploymentSituationOptions] = React.useState([] as ListOption[])
  const [activitySectorOptions, setActivitySectorOptions] = React.useState([] as ListOption[])
  const [activitySectorOptionsInit, setActivitySectorOptionsInit] = React.useState([] as ListOption[])
  const [taxAddressCountryValue, setTaxAddressCountryValue] = React.useState('')
  const [taxAddressProvinceValue, setTaxAddressProvinceValue] = React.useState('')
  const [taxAddressViaValue, setTaxAddressViaValue] = React.useState('')
  const [employmentSituationValue, setEmploymentSituationValue] = React.useState('')
  const [activitySectorValue, setActivitySectorValue] = React.useState('')

  const user = getUser();

  useEffect(()=>{
      getMasterDatas('genres').then(options=>{
        setGenderOptions(options);
      });
      getMasterDatas('countries').then(options=>{
        setCountryOptions(options);
        const taxAddressCountry = options.find(item=>item.valor===(user.datosFiscales?.domicilioPais?.toString() ?? ''));
        if(taxAddressCountry){
          setTaxAddressCountryValue(taxAddressCountry.descripcion);
        }
      });
      getMasterDatas('provinces').then(options=>{
        setProvinceOptions(options);
        const taxAddressProvince= options.find(item=>parseInt(item.valor)===parseInt(user.datosFiscales?.domicilioProvincia?.toString() ?? ''));
        if(taxAddressProvince){
          setTaxAddressProvinceValue(taxAddressProvince.descripcion);
        }
      });
      getMasterDatas('addressTypes').then(options=>{
        setViaOptions(options);
        const taxAddressVia= options.find(item=>item.valor===(user.datosFiscales?.domicilioTipoVia ?? ''));
        if(taxAddressVia){
          setTaxAddressViaValue(taxAddressVia.descripcion);
        }
      });
      getMasterDatas('laboralSituation').then(options=>{
        setEmploymentSituationOptions(options);
        const employmentSituation= options.find(item=>item.valor.toUpperCase()===(user.conocimientoCliente?.ocupacion?.toUpperCase() ?? ''));
        
        if(employmentSituation){
          setEmploymentSituationValue(employmentSituation.descripcion);
          handleChangeAnyField('employmentSituation', employmentSituation.descripcion);
        }
      });
  }, []);

  useEffect(()=>{
    switch (selectedIndex){
      case 1:
        trackStadistics('PAGE', '_DATOS_CONTACTO' );
        break;
      case 2:
        trackStadistics('PAGE', '_DOMICILIO_FISCAL' );
        break;
      case 3:
        trackStadistics('PAGE', '_CONOCIMIENTO_CLIENTE' );
        break;
      default:
        trackStadistics('PAGE', '_DATOS_PERSONALES' );
        break;
    }
  }, [selectedIndex]);

  const [formData, setFormData] = useState({
    idCard: user?.nif ?? "",

   //Personal data
   dateOfExpiry : !(user?.noCaducaNunca ?? false) ? user && user.fechaCaducidadNIF ? (validateUTCCodificationDate(user.fechaCaducidadNIF)) : (user && user.fechaCaducidadTarjetaResidenteTS ? (validateUTCCodificationDate(user.fechaCaducidadTarjetaResidenteTS)) : '') : '',
   neverExpires: user?.noCaducaNunca ?? false,
   name: user?.nombre ?? "",
   surname: user?.apellidos ?? "",
   birthDate: user && user.fechaNacimiento ? validateUTCCodificationDate(user.fechaNacimiento) : '',
   countryOfBirth: user?.paisNacimiento?.toString() ?? '',
   nationality: user?.nacionalidad?.toString() ?? '',
   countryOfResidence: user?.paisResidencia?.toString() ?? '',
   gender: user?.genero ?? "",


    //Contact information
    telephone: user?.telefono ?? "",
    mobile: user?.movil ?? "",
    email: user?.email?.toLowerCase() ?? "",

    //Tax address
    taxAddressCountry: "",
    taxAddressZipcode: user?.datosFiscales?.domicilioCP ?? "",
    taxAddressProvince: "",
    taxAddressLocality: user?.datosFiscales?.domicilioPoblacion ?? "",
    taxAddressVia: "",
    taxAddressNameVia: user?.datosFiscales?.domicilioVia ?? "",
    taxAddressNumber: user?.datosFiscales?.domicilioNumero ?? "",
    taxAddressFloor: user?.datosFiscales?.domicilioPiso ?? "",

    employmentSituation: user?.conocimientoCliente?.ocupacion ?? "",
    activitySector: user?.conocimientoCliente?.actividad ?? "",
    telefonicaGroupEmployee:user?.conocimientoCliente?.empleadoGrupoTelefonica ?? false,
    usTaxIdentificationNumber:user?.conocimientoCliente?.nifEEUU ?? false,
    otherActivitySector:user?.conocimientoCliente?.actividadEspecificar ?? "",
    personPublicResponsibility:user?.conocimientoCliente?.personaResponsabilidadPublica ?? false,
    personPublicResponsibilityPosition: user?.conocimientoCliente?.cargo ?? "",
    personPublicResponsibilityOrganism: user?.conocimientoCliente?.organismo ?? "",
    familyPublicResponsibilityOrganism:user?.conocimientoCliente?.familiarPEP ?? false,
    relatedPublicResponsibilityOrganism:user?.conocimientoCliente?.allegadoPep ?? false,
    ongPositionLast2Years:user?.conocimientoCliente?.ongPositionLast2Years ?? false,
    informationTrueVerifiable:false,
    legalOriginIncome:false,
    acceptPrivacyPolicy:false
  });

  //Submit
  const handleSubmit = (data: any): Promise<void> =>
    new Promise((resolve) => {
        setLoadingSave(true);

        //Validate
        let params : any = {
          gender: formData.gender,
          phone: formData.telephone,
          mobile: formData.mobile,
          email: formData.email,
          occupation: formData.employmentSituation,
          activity: formData.activitySector,
          telefonicaGroupEmployee: formData.telefonicaGroupEmployee,
          publicCharges: formData.personPublicResponsibility ? formData.personPublicResponsibilityPosition : 'NO',
          nifEEUU: formData.usTaxIdentificationNumber,
          activitySpecify: formData.otherActivitySector,
          personPublicResponsibility: formData.personPublicResponsibility,
          personPublicResponsibilityPosition: formData.personPublicResponsibilityPosition,
          personPublicResponsibilityOrganism: formData.personPublicResponsibilityOrganism,
          familyPublicResponsibilityOrganism: formData.familyPublicResponsibilityOrganism,
          relatedPublicResponsibilityOrganism: formData.relatedPublicResponsibilityOrganism,
          ongPositionLast2Years: formData.ongPositionLast2Years,
          lang: getCurrentLang(),
          logSource: getPlatform(),
          confirm: false
        };

        setDataClient(params).then((result)=>{
          if(result && result.result==='ok'){
            sign('Actualizar',
            () => {
              confirmSavePersonalData(params);
            },
            ()=>{
              setLoadingSave(false);
              resolve();
            },
            ()=>{

              setLoadingSave(true);
              const dateSign = moment().unix();
              params.confirm=true;
              params.electronic=true;
              params.dateSign=dateSign;
              setDataClient(params).then(result=>{
                if(result.tokenOperation){
                    digitalSign(result.tokenOperation
                    , ()=>{
                      confirmSavePersonalData(params, result.tokenOperation, dateSign);
                    }, ()=>{
                      setLoadingSave(false);
                    });
                }
              }).finally(()=>{
              })
            }
          );
        }else if(result && result.errors){
          const errors = result.errors as any;
          setFormErrors(result.errors);
          setLoadingSave(false);
        }
      });
    });

  const confirmSavePersonalData= (params:any, tokenOperation:string|null=null, dateSign:number|null=null): Promise<void> =>
    new Promise((resolve) => {
      setLoadingSave(true);
      const operationsKey = getOperationKey();
      const tokenSign = getTokenSign();

      if(operationsKey || tokenSign){
        params.operationsKey= operationsKey ? operationsKey : undefined;
        params.tokenSign= tokenSign ? tokenSign : undefined;
        params.tokenOperation= tokenOperation ? tokenOperation : undefined;
        params.dateSign= dateSign ? dateSign : undefined;
        params.electronic= tokenOperation && tokenSign ? true : false;
        params.confirm = true;
      }

      setDataClient(params).then((result)=>{
        if(result.result==='ok'){

          const userToSave = getUser();
          if(userToSave){
              userToSave.genero= formData.gender;
              userToSave.telefono= formData.telephone;
              userToSave.movil= formData.mobile;
              userToSave.email= formData.email;
              if(userToSave.conocimientoCliente){
                userToSave.conocimientoCliente.ocupacion= formData.employmentSituation;
                userToSave.conocimientoCliente.actividad= formData.activitySector;
                userToSave.conocimientoCliente.empleadoGrupoTelefonica= formData.telefonicaGroupEmployee;
                userToSave.conocimientoCliente.cargosPublicos= formData.personPublicResponsibility;
                userToSave.conocimientoCliente.nifEEUU= formData.usTaxIdentificationNumber;
                userToSave.conocimientoCliente.actividadEspecificar= formData.otherActivitySector;
                userToSave.conocimientoCliente.personaResponsabilidadPublica= formData.personPublicResponsibility;
                userToSave.conocimientoCliente.cargo= formData.personPublicResponsibilityPosition;
                userToSave.conocimientoCliente.organismo= formData.personPublicResponsibilityOrganism;
                userToSave.conocimientoCliente.familiarPEP= formData.familyPublicResponsibilityOrganism;
                userToSave.conocimientoCliente.allegadoPep= formData.relatedPublicResponsibilityOrganism;
                userToSave.conocimientoCliente.ongPositionLast2Years = formData.ongPositionLast2Years;
              }
              addUser(userToSave);
          }

          showToast({type:'ok', title: intl.formatMessage({id:'page_profile_save_response_ok_title'}), description: intl.formatMessage({id:'page_profile_save_response_ok_description'})});
        }else{
          showToast({type:'ko', title: intl.formatMessage({id:'page_profile_save_response_ko_title'}), description: intl.formatMessage({id:'page_profile_save_response_ko_description'})});
        }
      }).finally(()=>{
        setLoadingSave(false);
        resolve();
      });

    });

  //Handle fields
  const [formErrors, setFormErrors] = useState([] as any);


  const handleError=(name:string) =>{
    if(formErrors && formErrors[name]){
      const error = formErrors[name];
      if(error){
        return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
      }
    }
    return null;
  }

  const validateField = (name:string, value:any)=>{
    switch(name){
      case 'email':
        const expressionMail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if(!expressionMail.test(value)){
          formErrors[name] = 'is not a valid email';
          return false;
        }
        break;
    }
    return true;
  }

  const handleChangeField= (evt:any) => {
      const { target } = evt;
      const { name, value } = target;
      const newValues = {
        ...formData,
        [name]: value,
      };
      setFormData(newValues);
      if(handleError(name)){
        delete formErrors[name];
      }
      validateField(name, value);
  };

  const handleChangeAnyField= (name:string, value:any) => {
    const newValues = {
      ...formData,
      [name]: value,
    };
    setFormData(newValues);
    if(handleError(name)){
      delete formErrors[name];
    }
    switch(name){
      case 'employmentSituation':
          setEmploymentSituationValue(value);
          getMasterDatas('laboralSectors').then(optionsSectors=>{


            switch(value){
              case 'unemployed':
              case 'Householder':
              case 'Retired':
              case 'Student / Minor':
              case 'Desempleado':
              case 'Amo de casa':
              case 'Jubilado':
              case 'Estudiante/Menor de edad':
                const activitySectorOptionsNone = optionsSectors.filter(item=>item.valor==='None' || item.valor==='Ninguna');
                const activitySectorNone = activitySectorOptionsNone.find(item=>item.valor==='None' || item.valor==='Ninguna');
                setActivitySectorOptions(activitySectorOptionsNone);
                setActivitySectorValue(activitySectorNone ? activitySectorNone.valor : '');
                break;
              default:
                const activitySectorOptions = optionsSectors.filter(item=>item.valor!=='None' && item.valor!=='Ninguna');
                const activitySector = activitySectorOptions.find(item=>item.valor===activitySectorValue);
                setActivitySectorOptions(activitySectorOptions);
                setActivitySectorValue(activitySector ? activitySector.valor : '');
                break;
            }
            if(activitySectorOptionsInit.length===0){

              setActivitySectorOptionsInit(optionsSectors);
              setActivitySectorOptions(optionsSectors);
              const activitySector= optionsSectors.find(item=>item.valor.toUpperCase()===(user.conocimientoCliente?.actividad?.toUpperCase() ?? ''));
              if(activitySector){
                setActivitySectorValue(activitySector.descripcion);
                handleChangeAnyField('activitySector', activitySector.descripcion);
              }
            }
          });

          break;
      case 'activitySector':
        setActivitySectorValue(value);
        switch(value){
          case 'Otros':
          case 'Other':
            setVisibleOtherActivitySector(true);
            break;
          default:
            newValues.otherActivitySector = '';
            setFormData(newValues);
            setVisibleOtherActivitySector(false);
            break;
        }
        break;
      case 'personPublicResponsibility':
        if(!value){
          newValues.personPublicResponsibilityOrganism = '';
          newValues.personPublicResponsibilityPosition = '';
          setFormData(newValues);
        }
        break;
    }
    validateField(name, value);
  };

  //Submit disabled
  const isSubmitDisabled = () => {
    const countryDefault = (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11");
    switch(selectedIndex){
      case 0:
          return formData.gender.length === 0;
        case 1:
          return formData.telephone.length === 0 ||
                formData.mobile.length === 0 ||
                formData.email.length === 0 || !validateField('email', formData.email);
          case 3:
            return employmentSituationValue.length === 0 ||
                  activitySectorValue.length === 0 ||
                  (((formData.activitySector==='Other' || formData.activitySector==='Otros') && formData.otherActivitySector.length===0) ||
                  formData.personPublicResponsibility===true && (formData.personPublicResponsibilityPosition.length === 0 || formData.personPublicResponsibilityOrganism.length === 0)) ||
                  formData.informationTrueVerifiable===false ||
                  formData.legalOriginIncome===false ||
                  formData.acceptPrivacyPolicy===false;
                }
    return false;
  }

  return (
    <LayoutPrivateArea
      seo={{metaTitle: intl.formatMessage({id:'page_profile_customer_seo_title'})}}
      title= {intl.formatMessage({id:'page_profile_customer_seo_title'})}
      pageInfo={{option: process.env.REACT_APP_MENU_OPERATIVA_WEB_MIS_DATOS, role:'client'}}
      showBack
    >

      <ResponsiveLayout className='inner first'>
          <Box paddingBottom={screenSize.isDesktopOrBigger ? 32 : 0}>
              {screenSize.isDesktopOrBigger &&
                <Text5>{intl.formatMessage({ id: 'page_profile_customer_edit_profile_data' })}</Text5>
              }
          </Box>
      </ResponsiveLayout>
      <ResponsiveLayout className={!screenSize.isDesktopOrBigger ? 'fullScreen tabs--fixed' : 'inner'} backgroundColor={!screenSize.isDesktopOrBigger ? skinVars.colors.backgroundAlternative : 'transparent'}>
        <Box paddingTop={screenSize.isDesktopOrBigger ? 16 : 0}>
          <Tabs
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            tabs={[intl.formatMessage({id:'page_profile_customer_tabs_1'}), intl.formatMessage({id:'page_profile_customer_tabs_2'}), intl.formatMessage({id:'page_profile_customer_tabs_3'}), intl.formatMessage({id:'page_profile_customer_tabs_4'})].map((text) => ({
              text,
          }))}/>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner '+(selectedIndex===0 ? 'd-block' : 'd-none')+(!screenSize.isDesktopOrBigger ? ' with-tabs--fixed min' : '')}>
        <Box paddingY={32}>
          <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loading ? 'loading' : ''}>
            <Stack space={16}>
              <GridLayout verticalSpace={16} template='6+6'
                left={
                  <TextField disabled fullWidth name="idCard" label={intl.formatMessage({id:'page_register_id_card'})} onChange={handleChangeField} />
                }
                right={
                  <Stack space={8}>
                    <DateField disabled error={handleError('dateOfExpiry')!==null} helperText={handleError('dateOfExpiry')??undefined}  fullWidth name="dateOfExpiry" label={intl.formatMessage({id:'page_register_date_of_expiry'})} onChange={handleChangeField} />
                    <Checkbox disabled name="neverExpires" render={({controlElement, labelId}:any) => (
                      <Inline alignItems="center" space={16}>
                        {controlElement}
                        <Text1 regular>{intl.formatMessage({id:'page_register_never_expires'})}</Text1>
                      </Inline>
                    )}></Checkbox>
                  </Stack>
                }></GridLayout>

              <GridLayout verticalSpace={16} template='6+6'
                left={
                  <TextField disabled fullWidth name="name" error={handleError('name')!==null} helperText={handleError('name')??undefined} label={intl.formatMessage({id:'page_register_name'})} onChange={handleChangeField} />
                }
                right={
                  <TextField disabled fullWidth name="surname" error={handleError('surname')!==null} helperText={handleError('surname')??undefined} label={intl.formatMessage({id:'page_register_surname'})} onChange={handleChangeField} />
                }></GridLayout>

              <GridLayout verticalSpace={16} template='6+6'
                left={
                  <DateField disabled fullWidth name="birthDate" error={handleError('birthDate')!==null} helperText={handleError('birthDate')??undefined}  label={intl.formatMessage({id:'page_register_birthdate'})} onChange={handleChangeField} />
                }
                right={
                  <Select disabled fullWidth label={intl.formatMessage({id:'page_register_country_of_birth'})} name={'countryOfBirth'} error={handleError('countryOfBirth')!==null} helperText={handleError('countryOfBirth')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfBirth', val)}></Select>
                }></GridLayout>

              <GridLayout verticalSpace={16} template='6+6'
                left={
                  <Select disabled fullWidth label={intl.formatMessage({id:'page_register_nationality'})} name={'nationality'} error={handleError('nationality')!==null} helperText={handleError('nationality')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('nationality', val)}></Select>
                }
                right={
                  <Select disabled fullWidth label={intl.formatMessage({id:'page_register_country_of_residence'})} name={'countryOfResidence'} error={handleError('countryOfResidence')!==null} helperText={handleError('countryOfResidence')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfResidence', val)}></Select>
                }></GridLayout>


              <GridLayout verticalSpace={16} template='6+6'
                left={
                  <Select fullWidth label={intl.formatMessage({id:'page_register_gender'})} name={'gender'} error={handleError('gender')!==null} helperText={handleError('gender')??undefined} options={genderOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('gender', val)}></Select>
                }
                right={
                  <></>
                }></GridLayout>

				<ButtonFixedFooterLayout
					button={<ButtonLayout align='full-width'>
								<ButtonPrimary disabled={isSubmitDisabled()} showSpinner={loadingSave} submit>{intl.formatMessage({id:'page_profile_customer_save'})}</ButtonPrimary>
								</ButtonLayout>}
					>
					</ButtonFixedFooterLayout>
              </Stack>
            </Form>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner '+(selectedIndex===1 ? 'd-block' : 'd-none')}>
        <Box paddingY={32}>
          <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loading ? 'loading' : ''}>
            <Stack space={32}>
              <Stack space={16}>
                <GridLayout verticalSpace={16} template='6+6'
                  left={
                    <TextField
                      fullWidth
                      name="telephone"
                      error={handleError('telephone')!==null}
                      helperText={handleError('telephone')??undefined}
                      label={intl.formatMessage({id:'page_register_telephone'})} onChange={handleChangeField} />
                  }
                  right={
                    <TextField fullWidth name="mobile" error={handleError('mobile')!==null} helperText={handleError('mobile')??undefined} label={intl.formatMessage({id:'page_register_mobile'})} onChange={handleChangeField} />
                  }></GridLayout>

                <GridLayout verticalSpace={16} template='6+6'
                  left={
                    <EmailField fullWidth name="email" error={handleError('email')!==null} helperText={handleError('email')??undefined}  label={intl.formatMessage({id:'page_register_email'})} onChange={handleChangeField} maxLength={80} />
                  }
                  right={
                    <></>
                  }></GridLayout>



				<ButtonFixedFooterLayout
					button={<ButtonLayout align='full-width'>
								<ButtonPrimary disabled={isSubmitDisabled()} showSpinner={loadingSave}  submit>{intl.formatMessage({id:'page_profile_customer_save'})}</ButtonPrimary>
								</ButtonLayout>}
					>
					</ButtonFixedFooterLayout>

              </Stack>
            </Stack>
          </Form>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner '+(selectedIndex===2 ? 'd-block' : 'd-none')}>
        <Box paddingY={32}>
          <Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loading ? 'loading' : ''}>
            <Stack space={32}>
              <Stack space={16}>
                <Text3 regular>{intl.formatMessage({id:'page_profile_customer_change_tax_domicile'})}</Text3>

                <GridLayout verticalSpace={16} template='6+6'
                  left={
                    <TextField disabled value={taxAddressCountryValue} fullWidth label={intl.formatMessage({id:'page_register_country'})} name="taxAddressCountry"></TextField>
                  }
                  right={
                    <TextField disabled fullWidth name="taxAddressZipcode" label={intl.formatMessage({id:'page_register_zipcode'})} />
                  }></GridLayout>

                <GridLayout verticalSpace={16} template='6+6'
                  left={
                    <TextField fullWidth disabled value={taxAddressProvinceValue} label={intl.formatMessage({id:'page_register_province'})} name='taxAddressProvince' error={handleError('taxAddressProvince')!==null} helperText={handleError('taxAddressProvince')??undefined}></TextField>
                  }
                  right={
                    <TextField fullWidth disabled value={formData.taxAddressLocality} label={intl.formatMessage({id:'page_register_locality'})} name='taxAddressLocality'></TextField>
                  }></GridLayout>

                <GridLayout verticalSpace={16} template='6+6'
                  left={
                    <TextField fullWidth disabled label={intl.formatMessage({id:'page_register_via'})} name="taxAddressVia" value={taxAddressViaValue}></TextField>
                  }
                  right={
                    <TextField fullWidth disabled name="taxAddressNameVia" label={intl.formatMessage({id:'page_register_name_via'})} />
                  }></GridLayout>

                <GridLayout verticalSpace={16} template='6+6'
                  left={
                    <IntegerField fullWidth disabled label={intl.formatMessage({id:'page_register_number'})} name="taxAddressNumber"></IntegerField>
                  }
                  right={
                    <TextField optional disabled fullWidth name="taxAddressFloor" label={intl.formatMessage({id:'page_register_floor'})} />
                  }></GridLayout>

                  <Text1 regular>{intl.formatMessage({id:'page_profile_customer_explanation_tax_domicile'})}</Text1>
              </Stack>
            </Stack>
          </Form>
        </Box>
      </ResponsiveLayout>

      <ResponsiveLayout className={'inner '+(selectedIndex===3 ? 'd-block' : 'd-none')}>
        <Box padding={32}>
          <Form autoJump onSubmit={handleSubmit} initialValues={formData}>
            <Stack space={32}>

              <GridLayout verticalSpace={16} template='6+6'
                left={
                  <Select fullWidth label={intl.formatMessage({id:'page_register_employment_situation'})} name="employmentSituation" value={employmentSituationValue} error={handleError('employmentSituation')!==null} helperText={handleError('employmentSituation')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('employmentSituation', val)} options={employmentSituationOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                }
                right={
                  <Stack space={16}>
                    <Select fullWidth label={intl.formatMessage({id:'page_register_activity_sector'})} name="activitySector" value={activitySectorValue} error={handleError('activitySector')!==null} helperText={handleError('activitySector')??undefined} disabled={activitySectorOptions.length===0}  onChangeValue={(val:any)=> handleChangeAnyField('activitySector', val)} options={activitySectorOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                    {visibleOtherActivitySector &&
                      <TextField fullWidth name="otherActivitySector" error={handleError('otherActivitySector')!==null} helperText={handleError('otherActivitySector')??undefined}  label={intl.formatMessage({id:'page_register_other_activity_sector'})} onChange={handleChangeField} />
                    }
                  </Stack>
                }></GridLayout>

              <Box paddingY={8}>
                <Stack space={16}>
                  <Switch onChange={(val:any)=> handleChangeAnyField('telefonicaGroupEmployee', val)} name="telefonicaGroupEmployee"  render={({controlElement}) => (
                    <Inline alignItems="center" space="between">
                      <Text2 medium >
                        {intl.formatMessage({id:'page_register_telefonica_group_employee'})}
                      </Text2>
                      {controlElement}
                    </Inline>
                  )}></Switch>

                    <Switch onChange={(val:any)=> handleChangeAnyField('usTaxIdentificationNumber', val)} name="usTaxIdentificationNumber" render={({controlElement}) => (
                      <Inline alignItems="center" space="between">
                        <Text2 medium >
                          {intl.formatMessage({id:'page_register_us_tax_identification_number'})}
                        </Text2>
                        {controlElement}
                      </Inline>
                    )}></Switch>

                    <Switch onChange={(val:any)=> handleChangeAnyField('personPublicResponsibility', val)} name="personPublicResponsibility" render={({controlElement}) => (
                      <Inline alignItems="center" space="between">
                        <Inline alignItems="center" space={8}>
                          <Text2 medium >
                            {intl.formatMessage({id:'page_register_person_public_responsibility'})}
                          </Text2>
                          <Tooltip
                            targetLabel="help text"
                            target={<IconInformationUserRegular />}
                            position="top"
                            description={intl.formatMessage({id:'page_register_person_public_responsibility_help_text'})}
                          />
                        </Inline>
                        {controlElement}
                      </Inline>
                    )}></Switch>

                    {formData.personPublicResponsibility=== true &&
                      <>
                        <GridLayout verticalSpace={16} template='6+6'
                      left={
                        <TextField fullWidth name="personPublicResponsibilityPosition" error={handleError('personPublicResponsibilityPosition')!==null} helperText={handleError('personPublicResponsibilityPosition')??undefined}  label={intl.formatMessage({id:'page_register_person_public_responsibility_position'})} onChange={handleChangeField} />
                      }
                      right={
                        <TextField fullWidth name="personPublicResponsibilityOrganism" error={handleError('personPublicResponsibilityOrganism')!==null} helperText={handleError('personPublicResponsibilityOrganism')??undefined} label={intl.formatMessage({id:'page_register_person_public_responsibility_organism'})} onChange={handleChangeField} />
                      }></GridLayout>
                      </>
                    }

                    <Switch onChange={(val:any)=> handleChangeAnyField('familyPublicResponsibilityOrganism', val)} name="familyPublicResponsibilityOrganism" render={({controlElement}) => (
                      <Inline alignItems="center" space="between">
                        <Inline alignItems="center" space={8}>
                          <Text2 medium >
                            {intl.formatMessage({id:'page_register_family_public_responsibility_organism'})}
                          </Text2>
                          <Tooltip
                            targetLabel="help text"
                            target={<IconInformationUserRegular />}
                            position="top"
                            description={intl.formatMessage({id:'page_register_family_public_responsibility_organism_help_text'})}
                          />
                        </Inline>
                        {controlElement}
                      </Inline>
                    )}></Switch>

                    <Switch onChange={(val:any)=> handleChangeAnyField('relatedPublicResponsibilityOrganism', val)} name="relatedPublicResponsibilityOrganism" render={({controlElement}) => (
                      <Inline alignItems="center" space="between">
                        <Inline alignItems="center" space={8}>
                          <Text2 medium >
                            {intl.formatMessage({id:'page_register_related_public_responsibility_organism'})}
                          </Text2>
                          <Tooltip
                            targetLabel="help text"
                            target={<IconInformationUserRegular />}
                            position="top"
                            description={intl.formatMessage({id:'page_register_related_public_responsibility_organism_help_text'})}
                          />
                        </Inline>
                        {controlElement}
                      </Inline>
                    )}></Switch>

                    <Switch onChange={(val:any)=> handleChangeAnyField('ongPositionLast2Years', val)} name="ongPositionLast2Years" render={({controlElement}) => (
                      <Inline alignItems="center" space="between">
                        <Inline alignItems="center" space={8}>
                          <Text2 medium >
                          {intl.formatMessage({id:'page_register_question_ong'})}
                          </Text2>
                        </Inline>
                        {controlElement}
                      </Inline>
                    )}></Switch>
                  </Stack>
                </Box>

                <Box paddingTop={16}>
                  <Stack space={16}>
                    <Checkbox onChange={(val:any)=> handleChangeAnyField('informationTrueVerifiable', val)} name="informationTrueVerifiable" render={({controlElement, labelId}:any) => (
                      <Inline alignItems="center" space={16}>
                        {controlElement}
                        <Text3 regular id={labelId}>
                          {intl.formatMessage({id:'page_register_information_true_verifiable'})}
                        </Text3>
                      </Inline>
                    )}></Checkbox>

                    <Checkbox onChange={(val:any)=> handleChangeAnyField('legalOriginIncome', val)} name="legalOriginIncome" render={({controlElement, labelId}:any) => (
                      <Inline alignItems="center" space={16}>
                        {controlElement}
                        <Text3 regular id={labelId}>
                          {intl.formatMessage({id:'page_register_legal_origin_income'})}
                        </Text3>
                      </Inline>
                    )}></Checkbox>
                    <div className='html-content contain-politics-text' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_register_politics_text'})}}></div>

                    <Checkbox onChange={(val:any)=> handleChangeAnyField('acceptPrivacyPolicy', val)} name="acceptPrivacyPolicy" render={({controlElement, labelId}:any) => (
                        <Inline alignItems="center" space={16}>
                          {controlElement}
                          <Text3 regular id={labelId}>
                            {intl.formatMessage({id:'page_register_accept_privacy_policy'},{privacy_policy:<TextLink onPress={()=>{showAlert('_privacy_policy_')}}>{intl.formatMessage({id:'page_register_accept_privacy_policy_link_text'})}</TextLink>})}
                          </Text3>
                        </Inline>
                    )}></Checkbox>
                  </Stack>
                </Box>

				<ButtonFixedFooterLayout
					button={<ButtonLayout align='full-width'>
								<ButtonPrimary disabled={isSubmitDisabled()} showSpinner={loadingSave}  submit>{intl.formatMessage({id:'page_profile_customer_save'})}</ButtonPrimary>
								</ButtonLayout>}
					>
					</ButtonFixedFooterLayout>
            </Stack>
          </Form>
        </Box>
      </ResponsiveLayout>
    </LayoutPrivateArea>
  );
};

export default ProfileCustomerPage;
