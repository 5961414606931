
import { Box, ButtonFixedFooterLayout, ButtonLayout, ButtonPrimary, ButtonSecondary, Checkbox, Form, Grid, GridLayout, IconInformationUserRegular, Inline, LoadingBar, ResponsiveLayout, Select, Stack, Switch, Text2, Text3, Text4, TextField, Tooltip, useScreenSize } from '@telefonica/mistica';
import React, { useLayoutEffect, useState } from "react";
import { useIntl } from 'react-intl';

import { useIonRouter } from '@ionic/react';
import { ListOption } from '../../entities/commons/list-option.model';
import { Contract } from '../../entities/contract/contract';
import { getMasterDatas } from '../../utils/apiclient';
import { getOperationSubscription, getOperationTransfer, getUser, setOperationSubscription, setOperationTransfer } from '../../utils/storage';

interface ConfirmCustomerKnowledgeDataProps {
    contract: Contract | null,
    loading?: boolean,
    onCancel:() => void
    onConfirm:() => void
}

const ConfirmCustomerKnowledgeData: React.FC<ConfirmCustomerKnowledgeDataProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const router = useIonRouter();
    const [employmentSituationOptions, setEmploymentSituationOptions] = React.useState([] as ListOption[])
    const [activitySectorOptions, setActivitySectorOptions] = React.useState([] as ListOption[])
    const [activitySectorOptionsInit, setActivitySectorOptionsInit] = React.useState([] as ListOption[])
    const [employmentSituationValue, setEmploymentSituationValue] = React.useState('')
    const [activitySectorValue, setActivitySectorValue] = React.useState('')
    const [sourceMoneyOptions, setSourceMoneyOptions] = React.useState([] as ListOption[])
    const [visibleOtherActivitySector, setVisibleOtherActivitySector] = useState(false);
    const [loading, setLoading] = React.useState(true);
    //Loading form
    const [loadingForm, setLoadingForm] = React.useState(false);



    useLayoutEffect(()=>{
        getMasterDatas('laboralSituation').then(options=>{
            setEmploymentSituationOptions(options);
            const employmentSituation= options.find(item=>item.valor.toUpperCase()===(user.conocimientoCliente?.ocupacion?.toUpperCase() ?? ''));
            if(employmentSituation){
                setEmploymentSituationValue(employmentSituation.descripcion);
                handleChangeAnyField('employmentSituation', employmentSituation.descripcion);
            }

            getMasterDatas('sourceMoney').then(options=>{
                setSourceMoneyOptions(options);

                const option= options.find(item=>item.valor.toUpperCase()===(user.conocimientoCliente?.procedenciaPatrimonio?.toUpperCase()??''));
                if(option){
                    handleChangeAnyField('sourceMoney', option.valor);
                }

            }).finally(()=>{
                setLoading(false);
            });
        });
    }, []);


    //Form
    const user = getUser();

    const initFormData = {
        employmentSituation: user?.conocimientoCliente?.ocupacion ?? "",
        activitySector: user?.conocimientoCliente?.actividad ?? "",
        otherActivitySector: user?.conocimientoCliente?.actividadEspecificar ?? "",
        telefonicaGroupEmployee:user?.conocimientoCliente?.empleadoGrupoTelefonica ?? false,
        usTaxIdentificationNumber:user?.conocimientoCliente?.nifEEUU ?? false,
        sourceMoney: user?.conocimientoCliente?.procedenciaPatrimonio ?? "",
        personPublicResponsibility:user?.conocimientoCliente?.personaResponsabilidadPublica ?? false,
        personPublicResponsibilityPosition: user?.conocimientoCliente?.cargo ?? "",
        personPublicResponsibilityOrganism: user?.conocimientoCliente?.organismo ?? "",
        familyPublicResponsibilityOrganism:user?.conocimientoCliente?.familiarPEP ?? false,
        relatedPublicResponsibilityOrganism:user?.conocimientoCliente?.allegadoPep ?? false,
        ongPositionLast2Years:user?.conocimientoCliente?.ongPositionLast2Years ?? false,
        informationTrueVerifiable:false,
        legalOriginIncome:false
    }



    const [formData, setFormData] = useState(initFormData);
    const [formErrors, setFormErrors] = useState([] as any);

    //Handle fields
    const handleError=(name:string) =>{
            if(formErrors && formErrors[name]){
            const error = formErrors[name];
            if(error){
                return intl.formatMessage({id: 'page_pension_plans_operate_personal_data_'+error.toLowerCase().replaceAll(' ', '_')});
            }
        }
        return null;
    }

    const validateField = (name:string, value:any)=>{
      return true;
    }

    const handleChangeAnyField= (name:string, value:any) => {
      const newValues = {
        ...formData,
        [name]: value,
      };
      setFormData(newValues);
      if(handleError(name)){
        delete formErrors[name];
      }
      switch(name){
        case 'activitySector':
            setActivitySectorValue(value);
            switch(value){
              case 'Otros':
              case 'Other':
                setVisibleOtherActivitySector(true);
                break;
              default:
                newValues.otherActivitySector = '';
                setFormData(newValues);
                setVisibleOtherActivitySector(false);
                break;
            }
            break;
        case 'employmentSituation':
            setEmploymentSituationValue(value);
            getMasterDatas('laboralSectors').then(optionsSectors=>{
              switch(value){
                case 'unemployed':
                case 'Householder':
                case 'Retired':
                case 'Student / Minor':
                case 'Desempleado':
                case 'Amo de casa':
                case 'Jubilado':
                case 'Estudiante/Menor de edad':
                  const activitySectorOptionsNone = optionsSectors.filter(item=>item.valor==='None' || item.valor==='Ninguna');
                  const activitySectorNone = activitySectorOptionsNone.find(item=>item.valor==='None' || item.valor==='Ninguna');
                  setActivitySectorOptions(activitySectorOptionsNone);
                  setActivitySectorValue(activitySectorNone ? activitySectorNone.valor : '');
                  break;
                default:
                  const activitySectorOptions = optionsSectors.filter(item=>item.valor!=='None' && item.valor!=='Ninguna');
                  const activitySector = activitySectorOptions.find(item=>item.valor===activitySectorValue);
                  setActivitySectorOptions(activitySectorOptions);
                  setActivitySectorValue(activitySector ? activitySector.valor : '');
                  break;
              }
              if(activitySectorOptionsInit.length===0){

                setActivitySectorOptionsInit(optionsSectors);
                setActivitySectorOptions(optionsSectors);
                const activitySector= optionsSectors.find(item=>item.valor.toUpperCase()===(user.conocimientoCliente?.actividad ?? ''));
                if(activitySector){
                  setActivitySectorValue(activitySector.descripcion);
                  handleChangeAnyField('activitySector', activitySector.descripcion);
                }
              }
            });

            break;
        case 'personPublicResponsibility':
            if(!value){
                newValues.personPublicResponsibilityOrganism = '';
                newValues.personPublicResponsibilityPosition = '';
                setFormData(newValues);
            }
            break;
      }
      validateField(name, value);
    }

    const handleSubmit = (data: any): Promise<void> =>
        new Promise((resolve) => {
            setLoadingForm(true);

            const opSubscription = getOperationSubscription();
            if(opSubscription){
                opSubscription.employmentSituation = formData.employmentSituation;
                opSubscription.activitySector = formData.activitySector;
                opSubscription.otherActivitySector = formData.otherActivitySector;
                opSubscription.telefonicaGroupEmployee = formData.telefonicaGroupEmployee;
                opSubscription.usTaxIdentificationNumber = formData.usTaxIdentificationNumber;
                opSubscription.sourceMoney = formData.sourceMoney;
                opSubscription.personPublicResponsibility = formData.personPublicResponsibility;
                opSubscription.personPublicResponsibilityPosition = formData.personPublicResponsibilityPosition;
                opSubscription.personPublicResponsibilityOrganism = formData.personPublicResponsibilityOrganism;
                opSubscription.familyPublicResponsibilityOrganism = formData.familyPublicResponsibilityOrganism;
                opSubscription.relatedPublicResponsibilityOrganism = formData.relatedPublicResponsibilityOrganism;
                opSubscription.ongPositionLast2Years = formData.ongPositionLast2Years;
                opSubscription.informationTrueVerifiable = formData.informationTrueVerifiable;
                opSubscription.legalOriginIncome = formData.legalOriginIncome;
                setOperationSubscription(opSubscription);
            }

            const opTransfer = getOperationTransfer();
            if(opTransfer){
                opTransfer.employmentSituation = formData.employmentSituation;
                opTransfer.activitySector = formData.activitySector;
                opTransfer.otherActivitySector = formData.otherActivitySector;
                opTransfer.telefonicaGroupEmployee = formData.telefonicaGroupEmployee;
                opTransfer.usTaxIdentificationNumber = formData.usTaxIdentificationNumber;
                opTransfer.sourceMoney = formData.sourceMoney;
                opTransfer.personPublicResponsibility = formData.personPublicResponsibility;
                opTransfer.personPublicResponsibilityPosition = formData.personPublicResponsibilityPosition;
                opTransfer.personPublicResponsibilityOrganism = formData.personPublicResponsibilityOrganism;
                opTransfer.familyPublicResponsibilityOrganism = formData.familyPublicResponsibilityOrganism;
                opTransfer.relatedPublicResponsibilityOrganism = formData.relatedPublicResponsibilityOrganism;
                opTransfer.ongPositionLast2Years = formData.ongPositionLast2Years;
                opTransfer.informationTrueVerifiable = formData.informationTrueVerifiable;
                opTransfer.legalOriginIncome = formData.legalOriginIncome;
                setOperationTransfer(opTransfer);
            }


            prop.onConfirm();

            setLoadingForm(false);
            resolve();


        });
    //Submit disabled
    const isSubmitDisabled = () => {

        return employmentSituationValue.length === 0 ||
            activitySectorValue.length === 0 ||
            ((activitySectorValue==='Other' || activitySectorValue==='Otros') && formData.otherActivitySector.length===0) ||
            formData.sourceMoney.length===0 ||
            (formData.personPublicResponsibility===true && (formData.personPublicResponsibilityPosition.length === 0 || formData.personPublicResponsibilityOrganism.length === 0)) ||
            formData.informationTrueVerifiable===false ||
            formData.legalOriginIncome===false;
    }

    return (
        <>{prop.loading ?
            <></>
        :
        <>
            <LoadingBar  visible={loading || loadingForm} />
            <Form onSubmit={handleSubmit} initialValues={initFormData} className={loadingForm ? 'loading' : ''}>
                <ResponsiveLayout className="inner">
                    <ButtonFixedFooterLayout
                        button={
                            <ButtonLayout>
                                <ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_operate_confirm_personal_data_action_continue'})}</ButtonPrimary>
                            </ButtonLayout>
                        }
                        secondaryButton={screenSize.isDesktopOrBigger ? <ButtonLayout><ButtonSecondary onPress={() => {router.goBack()}}>{intl.formatMessage({id:'page_operate_confirm_personal_data_action_return_operate'})}</ButtonSecondary></ButtonLayout> : undefined}
                    >
                        <Box paddingBottom={screenSize.isDesktopOrBigger ? 56 : 16}>
                            <Stack space={24}>
                                <Text4 medium>{intl.formatMessage({id:'page_operate_confirm_personal_data_title'})}</Text4>

                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={[40, 16]}>
                                    <Stack space={16}>
                                        <Select fullWidth label={intl.formatMessage({id:'page_register_employment_situation'})} name="employmentSituation" value={employmentSituationValue} error={handleError('employmentSituation')!==null} helperText={handleError('employmentSituation')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('employmentSituation', val)} options={employmentSituationOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>

                                        <Select fullWidth label={intl.formatMessage({id:'page_register_activity_sector'})} name="activitySector" value={activitySectorValue}  error={handleError('activitySector')!==null} helperText={handleError('activitySector')??undefined} disabled={activitySectorOptions.length===0}  onChangeValue={(val:any)=> handleChangeAnyField('activitySector', val)} options={activitySectorOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>

                                        {visibleOtherActivitySector &&
                                            <TextField fullWidth name="otherActivitySector" error={handleError('otherActivitySector')!==null} helperText={handleError('otherActivitySector')??undefined}  label={intl.formatMessage({id:'page_register_other_activity_sector'})} onChangeValue={(val:any)=> handleChangeAnyField('otherActivitySector', val)} />
                                        }

                                        <Select fullWidth label={intl.formatMessage({id:'page_register_source_money'})} name="sourceMoney" value={formData.sourceMoney} error={handleError('sourceMoney')!==null} helperText={handleError('sourceMoney')??undefined} disabled={sourceMoneyOptions.length===0}  onChangeValue={(val:any)=> handleChangeAnyField('sourceMoney', val)} options={sourceMoneyOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
                                    </Stack>
                                </Grid>

                                <Grid columns={screenSize.isDesktopOrBigger ? 2 : 1} gap={[40, 16]}>
                                    <Stack space={16}>
                                        <Switch onChange={(val:any)=> handleChangeAnyField('telefonicaGroupEmployee', val)} name="telefonicaGroupEmployee"  render={({controlElement}) => (
                                        <Inline alignItems="center" space="between">
                                            <Text2 medium >
                                            {intl.formatMessage({id:'page_register_telefonica_group_employee'})}
                                            </Text2>
                                            {controlElement}
                                        </Inline>
                                        )}></Switch>

                                        <Switch onChange={(val:any)=> handleChangeAnyField('usTaxIdentificationNumber', val)} name="usTaxIdentificationNumber" render={({controlElement}) => (
                                        <Inline alignItems="center" space="between">
                                            <Text2 medium >
                                            {intl.formatMessage({id:'page_register_us_tax_identification_number'})}
                                            </Text2>
                                            {controlElement}
                                        </Inline>
                                        )}></Switch>

                                        <Switch onChange={(val:any)=> handleChangeAnyField('personPublicResponsibility', val)} name="personPublicResponsibility" render={({controlElement}) => (
                                        <Inline alignItems="center" space="between">
                                            <Inline alignItems="center" space={8}>
                                            <Text2 medium >
                                                {intl.formatMessage({id:'page_register_person_public_responsibility'})}
                                            </Text2>
                                            <Tooltip
                                                targetLabel="help text"
                                                target={<IconInformationUserRegular />}
                                                position="top"
                                                description={intl.formatMessage({id:'page_register_person_public_responsibility_help_text'})}
                                            />
                                            </Inline>
                                            {controlElement}
                                        </Inline>
                                        )}></Switch>

                                        {formData.personPublicResponsibility=== true &&
                                        <>
                                            <GridLayout verticalSpace={16} template='6+6'
                                                left={
                                                    <TextField fullWidth name="personPublicResponsibilityPosition" error={handleError('personPublicResponsibilityPosition')!==null} helperText={handleError('personPublicResponsibilityPosition')??undefined}  label={intl.formatMessage({id:'page_register_person_public_responsibility_position'})} onChangeValue={(val:any)=> handleChangeAnyField('personPublicResponsibilityPosition', val)} />
                                                }
                                                right={
                                                    <TextField fullWidth name="personPublicResponsibilityOrganism" error={handleError('personPublicResponsibilityOrganism')!==null} helperText={handleError('personPublicResponsibilityOrganism')??undefined} label={intl.formatMessage({id:'page_register_person_public_responsibility_organism'})} onChangeValue={(val:any)=> handleChangeAnyField('personPublicResponsibilityOrganism', val)} />
                                                }
                                            />
                                        </>
                                        }

                                        <Switch onChange={(val:any)=> handleChangeAnyField('familyPublicResponsibilityOrganism', val)} name="familyPublicResponsibilityOrganism" render={({controlElement}) => (
                                            <Inline alignItems="center" space="between">
                                                <Inline alignItems="center" space={8}>
                                                    <Text2 medium >
                                                        {intl.formatMessage({id:'page_register_family_public_responsibility_organism'})}
                                                    </Text2>
                                                    <Tooltip
                                                        targetLabel="help text"
                                                        target={<IconInformationUserRegular />}
                                                        position="top"
                                                        description={intl.formatMessage({id:'page_register_family_public_responsibility_organism_help_text'})}
                                                    />
                                                </Inline>
                                                {controlElement}
                                            </Inline>
                                            )}
                                        />

                                        <Switch onChange={(val:any)=> handleChangeAnyField('relatedPublicResponsibilityOrganism', val)} name="relatedPublicResponsibilityOrganism" render={({controlElement}) => (
                                            <Inline alignItems="center" space="between">
                                                <Inline alignItems="center" space={8}>
                                                <Text2 medium >
                                                    {intl.formatMessage({id:'page_register_related_public_responsibility_organism'})}
                                                </Text2>
                                                <Tooltip
                                                    targetLabel="help text"
                                                    target={<IconInformationUserRegular />}
                                                    position="top"
                                                    description={intl.formatMessage({id:'page_register_related_public_responsibility_organism_help_text'})}
                                                />
                                                </Inline>
                                                {controlElement}
                                            </Inline>
                                        )}/>

                                        <Switch onChange={(val:any)=> handleChangeAnyField('ongPositionLast2Years', val)} name="ongPositionLast2Years" render={({controlElement}) => (
                                            <Inline alignItems="center" space="between">
                                                <Inline alignItems="center" space={8}>
                                                <Text2 medium >
                                                    {intl.formatMessage({id:'page_register_question_ong'})}
                                                </Text2>
                                                </Inline>
                                                {controlElement}
                                            </Inline>
                                        )}/>
                                    </Stack>
                                </Grid>

                                <Stack space={16}>
                                    <Checkbox onChange={(val:any)=> handleChangeAnyField('informationTrueVerifiable', val)} name="informationTrueVerifiable" render={({controlElement, labelId}:any) => (
                                        <Inline alignItems="center" space={16}>
                                            {controlElement}
                                            <Text3 regular id={labelId}>
                                                {intl.formatMessage({id:'page_register_information_true_verifiable_detail'})}
                                            </Text3>
                                        </Inline>
                                    )}></Checkbox>

                                    <Checkbox onChange={(val:any)=> handleChangeAnyField('legalOriginIncome', val)} name="legalOriginIncome" render={({controlElement, labelId}:any) => (
                                        <Inline alignItems="center" space={16}>
                                            {controlElement}
                                            <Text3 regular id={labelId}>
                                                {intl.formatMessage({id:'page_register_legal_origin_income'})}
                                            </Text3>
                                        </Inline>
                                        )}></Checkbox>
                                </Stack>
                            </Stack>
                        </Box>
                    </ButtonFixedFooterLayout>
                </ResponsiveLayout>
            </Form>
        </>}</>
    );
};

export default ConfirmCustomerKnowledgeData;