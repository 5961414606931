import { Box, Boxed, ButtonLayout, ButtonSecondary, Grid, Stack, Text2, Text4, skinVars, useScreenSize, useWindowSize, SkeletonRectangle} from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { Contract } from '../../entities/contract/contract';
import { getCurrentUserProduct } from '../../utils/storage';
import { formatBalance, formatNetAssetValue } from '../../utils/utils';
import './ContractInfo.scss';

interface ContractInfoProps {
    contract: Contract | null
    loading?: boolean
}

const ContractInfo: React.FC<ContractInfoProps> = (prop) => {
    const intl = useIntl()
    const screenSize = useScreenSize();
    const size = useWindowSize();
    const [columnGrid, setColumnGrid] = useState(3 as any);
    const [numberFields, setNumberFields] = useState(null as null|number);
    const [contractNumber, setContractNumber] = useState(null as null|string);
    const [rights, setRights] = useState(null as null|string);
    const [balance, setBalance] = useState(null as null|string);
    const [ownership, setOwnership] = useState(null as null|string);
    const [netAssetValue, setNetAssetValue] = useState(null as null|string);
    const [units, setUnits] = useState(null as null|string);
    const currentUserProduct = getCurrentUserProduct();



    useEffect(()=>{
        if(prop.contract){
            let calcNumberFields = 2;
            setContractNumber(prop.contract.numContrato??'');
            setNetAssetValue(prop.contract?.derechos?.valorLiquidativo?.valor ? formatNetAssetValue(prop.contract?.derechos?.valorLiquidativo?.valor) : intl.formatMessage({id:'page_product_customer_not_available_short'}));
            switch(prop.contract.plan?.cdCompania?.toString()??''){
                case process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES:
                    setRights(prop.contract.derechos?.derechos ? formatBalance(prop.contract.derechos?.derechos) : '0,00');
                    setUnits(prop.contract.derechos?.unidades ? formatBalance(prop.contract.derechos?.unidades) : '0,00');
                    setOwnership(currentUserProduct?.nbTitular ? currentUserProduct.nbTitular : '');
                    calcNumberFields = 5;
                    break;
                case process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS:
                    setBalance(prop.contract.derechos?.derechos ? formatBalance(prop.contract.derechos?.derechos) : '0,00');
                    setUnits(prop.contract.derechos?.unidades ? formatBalance(prop.contract.derechos?.unidades) : '0,00')
                    setOwnership(currentUserProduct?.nbTitular ? currentUserProduct.nbTitular : '');
                    calcNumberFields = 5;
                break;
                case process.env.REACT_APP_COMPANIA_FONDITEL_OTROSPRODUCTOS:
                    setBalance(prop.contract.derechos?.derechos ? formatBalance(prop.contract.derechos?.derechos) : '0,00');
                    calcNumberFields = 3;
                break;
            }
            setColumnGrid(calcNumberFields);
            setNumberFields(calcNumberFields);
        }

    }, [prop.contract]);

    useEffect(()=>{
        if(size.width<450){
            setColumnGrid(2);
        }else if(size.width<1200){
            setColumnGrid(3);
        }else{
            setColumnGrid(numberFields);
        }
    }, [size, numberFields]);

return (
        <>{(prop.loading || !numberFields) ?
                <SkeletonRectangle height={120} />
            :
                <>
                    <style>{
                        '.contractInfo--content .boxValue:before {background: '+skinVars.colors.divider+';}'+
                        '.contractInfo--content:before {background: '+skinVars.colors.navigationBarDivider+';}'
                    }</style>
                    {numberFields &&
                        <Boxed isInverse className={'contractInfo--content'+((numberFields??5)<5 ? ' box--auto' : '')}>
                            <Box padding={screenSize.isDesktopOrBigger ? 32 : 16}>
                                <Stack space={16}>
                                    {screenSize.isMobile &&
                                        <Text2 regular>{prop.contract?.plan?.descripcion}</Text2>
                                    }
                                    <Grid columns={columnGrid} gap={16}>

                                        {rights &&
                                            <Box className='boxValue'>
                                                <Stack space={4}>
                                                    <Text2 regular>{intl.formatMessage({id:'page_contract_data_contract_info_rights'})}</Text2>
                                                    <Text4 medium>{rights}€</Text4>
                                                </Stack>
                                            </Box>
                                        }

                                        {balance &&
                                            <Box className='boxValue'>
                                                <Stack space={4}>
                                                    <Text2 regular>{intl.formatMessage({id:'page_contract_data_contract_info_balance'})}</Text2>
                                                    <Text4 medium>{balance}€</Text4>
                                                </Stack>
                                            </Box>
                                        }

                                        {ownership &&
                                            <Box className='boxValue'>
                                                <Stack space={4}>
                                                    <Text2 regular>{intl.formatMessage({id:'page_contract_data_contract_info_ownership'})}</Text2>
                                                    <Text4 medium>{ownership}</Text4>
                                                </Stack>
                                            </Box>
                                        }

                                        {units &&
                                            <Box className='boxValue'>
                                                <Stack space={4}>
                                                    <Text2 regular>{intl.formatMessage({id:'page_contract_data_contract_info_units'})}</Text2>
                                                    <Text4 medium>{units}</Text4>
                                                </Stack>
                                            </Box>
                                        }

                                        <Box className='boxValue'>
                                            <Stack space={4}>
                                                <Text2 regular>{intl.formatMessage({id:'page_contract_data_contract_info_netassetvalue'})}</Text2>
                                                <Text4 medium>{netAssetValue}</Text4>
                                            </Stack>
                                        </Box>
                                    </Grid>

                                    {/*!screenSize.isDesktopOrBigger &&
                                        <ButtonLayout align='full-width'>
                                            <ButtonSecondary onPress={()=> {}}>Información del plan</ButtonSecondary>
                                        </ButtonLayout>
                                    */}
                                </Stack>
                            </Box>
                        </Boxed>
                    }
                </>
            }
        </>
    );
};

export default ContractInfo;
